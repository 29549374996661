import * as React from 'react';
import {ScrollView, ImageBackground, Image, Button, Text, View, Platform, StyleSheet, TouchableOpacity} from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
function FeedScreen({navigation}) {
  return (
    <View style={{flex: 1, backgroundColor: '#45AB9F' }}>
    <ScrollView style={{flex: 1, backgroundColor: '#45AB9F' }}>
      

       <View style={styles.row}>
       <Image source={require('./assets/back2.png')} resizeMode="scale" style={styles.GameImage}>
      </Image>
     

      <TouchableOpacity
          onPress={() =>  navigation.navigate('Play Game', {name: 'Jane'})}
          style={[styles.button]}>
                 

         <Text
            style={[
              styles.buttonLabel
            ]}>
            {"PLAY GAME"}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() =>  navigation.navigate('Play Game', {name: 'Jane'})}
          style={[styles.button]}>
         <Text
            style={[
              styles.buttonLabel
            ]}>
            {"PLAY GAME"}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() =>  navigation.navigate('Play Game', {name: 'Jane'})}
          style={[styles.button]}>
         <Text
            style={[
              styles.buttonLabel
            ]}>
            {"PLAY GAME"}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() =>  navigation.navigate('Play Game', {name: 'Jane'})}
          style={[styles.button]}>
         <Text
            style={[
              styles.buttonLabel
            ]}>
            {"PLAY GAME"}
          </Text>
        </TouchableOpacity>
     <View style={{flex: 1, backgroundColor: '#45AB9F', marginTop:25, minHeight: '20px' }}>

     </View>
        </View>
      
    </ScrollView>
  
     </View>
  );
}



function PlayGameScreen({navigation, route}) {
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#45AB9F'  }}>
      <iframe frameborder="0" src="https://itch.io/embed-upload/9561852?color=333333" allowfullscreen="" width="1920" height="1080"><a href="https://vedoystudio.itch.io/float-boat">Play Float Boat on itch.io</a></iframe>
    </View>
  );
}



function ProfileScreen() {
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#45AB9F'  }}>
      <Text>Profile!</Text>
    </View>
  );
}


  const Tab = createMaterialTopTabNavigator();




function MyTabs() {
  return (
    <Tab.Navigator
      initialRouteName="Feed"
      screenOptions={{
        tabBarActiveTintColor: '#e91e63',
        tabBarLabelStyle: { fontSize: 12, color: 'white' },
        tabBarStyle: { backgroundColor: '#2E294E' },
      }}
    >
      <Tab.Screen
        name="Feed"
        component={FeedScreen}
        options={{ tabBarLabel: 'Home' }}
      />
      <Tab.Screen
        name="Play Game"
        component={PlayGameScreen}
        options={{ tabBarLabel: 'Play Game' }}
      />
      <Tab.Screen
        name="Profile"
        component={ProfileScreen}
        options={{ tabBarLabel: 'Profile' }}
      />
    </Tab.Navigator>
  );
}






export default function App() {
  return (
    <NavigationContainer>
      <MyTabs />
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'aliceblue',
  },
  box: {
    width: 50,
    height: 50,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  button: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 4,
    backgroundColor: 'oldlace',
    alignSelf: 'flex-start',
    marginHorizontal: '1%',
    marginBottom: 15,
    minWidth: '48%',
    minHeight: '40%',
    textAlign: 'center',
    backgroundColor: '#2E294E',
    marginTop: 25,
  },
  selected: {
    backgroundColor: 'coral',
    borderWidth: 0,
  },
  buttonLabel: {
    fontSize: 30,
    fontWeight: '500',
    color: 'white',
  },
  selectedLabel: {
    color: 'white',
  },
  label: {
    textAlign: 'center',
    marginBottom: 10,
    fontSize: 24,
  },
  tinyLogo: {
    width: '100%',
    height: '100%'
  },
  image: {
    flex: 1,
    justifyContent: 'center',
  },
  GameImage: {
    flex: 1,
    width: '95%',
    height: '40vw'
  },
  text: {
    color: 'white',
    fontSize: 42,
    lineHeight: 84,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#000000c0',
  },
});
